.campaign-option {
  color: #363849 !important;
  border: 2px solid rgba(167, 169, 189, 0.2) !important;
  border-radius: 4px;
  font-weight: 500;
  font-family: Poppins;
}

.add-campaign-option {
  color: #1f95ff !important;
  border: 2px solid rgba(167, 169, 189, 0.2) !important;
  border-radius: 4px;
  font-weight: 500;
  font-family: Poppins;
}
