.mobile {
  width: 500px;
  padding: 20px;
  border: 1px solid black;
  background: #ececec;
}

.mobile-top {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: white;
  padding: 10px;
}

.mobile-top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  font-size: 20px;
  color: #1a0dab;
}

.sitelink {
  padding: 10px;
}

.fade {
  animation-name: fadeIn;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.sitelink ul {
  list-style: none;
  color: #1a0dab;
}

.call {
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
  border-top: 1px solid white;
}
