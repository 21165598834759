@import url("https://rsms.me/inter/inter.css");

body {
  font-family: "Inter, sans-serif";
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: lightcyan;
  opacity: 0.2;
  z-index: 1;
}

@media screen and (min-width: 992px) {
  .css-2oqyjj {
    padding: 10px 100px !important;
  }
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
