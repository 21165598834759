.login-input {
  border-color: transparent !important;
  box-shadow: none !important;
}

.login-label {
  background: transparent !important;
}

.login-controller {
  border: 1px solid rgba(167, 169, 189, 0.25);
  border-left: 4px solid #3182ce;
  padding: 20px 0 6px 0;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}
